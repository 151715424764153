import { AppConfig } from "app/core";
import { commonEnvironment } from "./environment-common";
import { signal } from "@angular/core";

export const environment: AppConfig = {
  ...commonEnvironment,
  botName: "clickrh",
  backendBaseUrl: "https://ui-api.chatbotrh.laposte.fr/v0/",

  checkAvailability: true,
  hasMaintenance: true,

  hostDimensions: {
    openHeight: 650,
    openWidth: 450,
    closedHeight: 130,
    closedWidth: 180,
    closedMobileHeight: 100,
    closedMobileWidth: 100,
  },

  hasConfigurationFromBackend: true,
  idrhSupport: { required: true },
  hasResetConversationOnReload: false,

  headerLogoUrl: "/assets/avatar.svg",
  headerBanner: { text: signal<string>("") },
  personalDataMessage: {
    text: signal<string>(
      "Nous traitons vos données conformément à notre charte de confidentialité. <strong><a href='https://ui.chatbotrh.laposte.fr/assets/static/pdf/Mentions_RGPD_JUIL2024.pdf' target='_blank'>En savoir plus sur la charte</a> </strong>"
    ),
  },
  reducedBotLogoUrl: "/assets/avatar-circled.svg",
  reducedBotHasWelcomeText: true,

  hasCustomButtonColor: true,
  //Click RH has long messages, and if we scroll on messages, we don't have time to read message before it scrolls. That's why clickRH has special scroll on block.
  scrollOnMessages: false,

  messageAvatarUrl: "/assets/avatar.svg",

  whereToSetDefaultIdrh: ["localhost", "ui.chatbotrh", "admin.chatbotrh"],
  defaultIdrh: "pxxxxxx",
  defaultIdrhBotTest: "ppppppp",
};
