import { UserStore } from "./user.store";
import { AppConfig, AppHostService, PersistentStore } from "app/core";
import { PersistedUser, User } from "./user.model";
import { toSignal } from "@angular/core/rxjs-interop";

/** A store to access data on the current user, its IDRH in particular. */
export class UserStoreIdrhImpl implements UserStore {
  private persistedUserSignal;

  constructor(
    private idrhRequired: boolean,
    private hostService: AppHostService,
    private persistentStore: PersistentStore<PersistedUser>,
    private config: AppConfig
  ) {
    this.persistedUserSignal = toSignal(this.persistentStore.get());
  }

  /** @return Data on the current user */
  public get(): User {
    const persistedUser = this.persistedUserSignal() ?? {
      idrh: "",
      IDRH: "",
      "user-jwt": "",
    };
    let actualDefaultIdrh = "";
    if (
      this.config.whereToSetDefaultIdrh &&
      this.config.whereToSetDefaultIdrh.some((substring) =>
        this.hostService.currentUrl.includes(substring)
      )
    ) {
      if (this.config.defaultIdrhBotTest && this.hostService.isTestBot) {
        actualDefaultIdrh = this.config.defaultIdrhBotTest;
      }
      if (this.config.defaultIdrh && !this.hostService.isTestBot) {
        actualDefaultIdrh = this.config.defaultIdrh;
      }
    }

    const idrh = (
      persistedUser.idrh ||
      persistedUser.IDRH ||
      actualDefaultIdrh
    ).toLocaleLowerCase();
    return { idrh, token: persistedUser["user-jwt"] ?? "" };
  }

  /** @return The entire allowed IDRHH */
  /* To not directly set the entire allowed idrh in env variable, only half of it is in config, and the other half is defined here. */
  private computeAllowedIdrh(): string {
    const allowedIdrhsFirstHalf = this.config.allowedIdrhs?.toLocaleLowerCase() || "";
    const allowedIdrhsOtherHalf = "*B*U*1*,*V*B*1*2* *F*W*8*,*V*V*1*1".toLocaleLowerCase();
    let computedIdrh = "";
    for (let i = 0; i < allowedIdrhsFirstHalf.length; i++) {
      const nextCaracter = i % 2 ? allowedIdrhsOtherHalf[i] : allowedIdrhsFirstHalf[i];
      computedIdrh = computedIdrh + nextCaracter;
    }
    return computedIdrh;
  }

  /** @return Whether an internal user has a valid IDRH */
  isUserValid() {
    const user = this.get();
    if (user.idrh) {
      // always show on service-now
      if (this.hostService.currentUrl.includes("service-now")) {
        return true;
      }

      const computedAllowedIdrh = this.computeAllowedIdrh();
      // check the idrh
      if (user.idrh.length === 7) {
        //if idrh start with P or is in allowed list of Probayes idrh
        if (
          user.idrh.startsWith("p") ||
          computedAllowedIdrh.toLocaleLowerCase().includes(user.idrh)
        ) {
          return true;
        } else {
          console.error("idrh forbidden");
        }
      } else {
        console.error("idrh forbidden : length is not 7");
      }
    }
    return false;
  }
  isMissingRequiredUser() {
    return this.idrhRequired && !this.isUserValid();
  }
}
